module.exports = [
  [0, 1, 2, 8, 9, 10, 16, 17, 18], // 0
  [1, 2, 3, 9, 10, 11, 17, 18, 19], // 1
  [2, 3, 4, 10, 11, 12, 18, 19, 20], // 2
  [3, 4, 5, 11, 12, 13, 19, 20, 21], // 3
  [5, 6, 7, 13, 14, 15, 21, 22, 23], // 4
  [5, 6, 7, 13, 14, 15, 21, 22, 23], // 5
  [9, 10, 11, 17, 18, 19, 25, 26, 27], // 6
  [10, 11, 12, 18, 19, 20, 26, 27, 28], // 7
  [13, 14, 15, 21, 22, 23, 29, 30, 31], // 8
  [13, 14, 15, 21, 22, 23, 29, 30, 31], // 9
  [8, 9, 10, 16, 17, 18, 24, 25, 26], // 10
  [8, 9, 10, 16, 17, 18, 24, 25, 26], // 11
  [9, 10, 11, 17, 18, 19, 25, 26, 27], // 12
  [11, 12, 13, 19, 20, 21, 27, 28, 29], // 13
  [12, 13, 14, 20, 21, 22, 28, 29, 30], // 14
  [13, 14, 15, 21, 22, 23, 29, 30, 31], // 15
  [8, 9, 10, 16, 17, 18, 24, 25, 26], // 16
  [8, 9, 10, 16, 17, 18, 24, 25, 26], // 17
  [11, 12, 13, 19, 20, 21, 27, 28, 29], // 18
  [11, 12, 13, 19, 20, 21, 27, 28, 29] // 19
];
