'use strict';

const UAParser = require('ua-parser-js');
const parser = new UAParser();
const name = parser.getResult().browser.name;
const deviceType = parser.getDevice().type;
const mobileDeviceTypes = [
  'mobile',
  'wearable'
];
const isMobile = mobileDeviceTypes.includes(deviceType);

function init() {
  const root = document.getElementsByTagName('html')[0]; // '0' to assign the first (and only `HTML` tag)
  switch (name) {
    case 'IE':
      root.classList.add('is_ie');
      break;
    case 'Edge':
      root.classList.add('is_edge');
      break;
    case 'Chrome':
      root.classList.add('is_chrome');
      break;
    case 'Firefox':
      root.classList.add('is_ff');
      break;
    case 'Safari':
      root.classList.add('is_safari');
      break;
    case 'Android':
      root.classList.add('is_android');
  }
}

module.exports = { init, isMobile };
