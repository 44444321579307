'use strict';
const $ = require('jquery');
module.exports = function () {
  const $bubbles = $('#circle-cont div:not(.not)');
  let timer;
  $bubbles.hover(function (e) {
    clearTimeout(timer);
    let $target = $(this)[0];
    $bubbles.each(function (index, value) {
      if ($target === $(this)[0]) {
        $(this).addClass('active');
      } else {
        $(this).addClass('inactive').removeClass('active');
      }
    });
  }, () => {
    timer = setTimeout(() => {
      $bubbles.removeClass('active inactive');
    }, 300);
  });
};
