module.exports = [
  'blue-bg', // 0
  'blue-bg', // 1
  'blue-bg', // 2
  'white-bg', // 3
  'red-bg', // 4
  'red-bg', // 5
  'white-bg', // 6
  'red-bg', // 7
  'red-bg', // 8
  'red-bg', // 9
  'red-bg', // 10
  'blue-bg', // 11
  'blue-bg', // 12
  'blue-bg', // 13
  'red-bg', // 14
  'blue-bg', // 15
  'blue-bg', // 16
  'white-bg', // 17
  'white-bg', // 18
  'white-bg', // 19
  'white-bg', // 20
  'red-bg', // 21
  'white-bg', // 22
  'white-bg', // 23
  'red-bg', // 24
  'blue-bg', // 25
  'blue-bg', // 26
  'blue-bg', // 27
  'red-bg', // 28
  'blue-bg', // 29
  'white-bg', // 30
  'white-bg' // 31
];
