'use strict';

const $ = require('jquery');
const slick = require('slick-carousel'); // eslint-disable-line no-unused-vars
const ua = require('./ua-detection');
const insert = document.getElementById('insert');
const articleLimit = ua.isMobile ? 8 : 3;

const FLAPI_ENDPOINT = 'https://flapi.flipboard.com';
const INSIGHT_REMOTE_ID = 'sid/35vh6nnty/forbrands';
const INSIGHT_LIMIT = 30;
const INSIGHT_GROUPS = [ 'The Work', 'Most Read in Advertising' ];

let slider;
let winWidth = window.innerWidth;

module.exports = function () {
  const remoteID = encodeURIComponent(INSIGHT_REMOTE_ID);
  const groups = encodeURIComponent(INSIGHT_GROUPS.join(';'));
  const limit = encodeURIComponent(INSIGHT_LIMIT)
  const sourceUrl = `${FLAPI_ENDPOINT}/brands/v1/sections?remoteid=${remoteID}&groups=${groups}&limit=${limit}`

  $.ajax({
    type: 'GET',
    url: sourceUrl,
    success: (api) => {
      $.each(api.sections, (index) => {
        insert.innerHTML += `
        <div class="large-6 column animation-article article-end-state animate-in">
          <h2 class="category">${api.sections[index].sectionTitle}</h2>
        </div>
        <div class="animation-article article-end-state animate-in readmore large-6 column">
          <a target="_blank" href="https://flipboard.com/@forbrands/the-insight-35vh6nnty/">See More</a>
        </div> <div class="sliderContainer">`;
        $.each(api.sections[index].articles, (indexArticle) => {
          // Limit number of articles
          if (indexArticle > (articleLimit - 1)) return false;
          const article = api.sections[index].articles[indexArticle];
          document.querySelectorAll('.sliderContainer')[index].innerHTML += `
          <div class="articleSlider animation-article article-end-state animate-in small-12 medium-4 large-4 column">
            <a href="${article.url}">
              <div class="cont-img">
                <div class="img" style="background-image: url(${(article.img) > '' ? article.img : '/img/placeholder.jpg'})"></div>
              </div>
              <h2>${article.title}</h2>
              <span class="source">${article.source} - ${article.author}</span>
            </a>
          </div>`;
        });
        // CLOSE SLIDER CONTAINER
        insert.innerHTML += `</div>`;
      });
    }
  }).done(() => {
    slider = $('.sliderContainer');
    startSlider();
    require('./article-hover')();
  });

  $(document).ajaxComplete(function () {
    require('./article-animation')();
  });

  function startSlider () {
    winWidth = window.innerWidth;
    if (winWidth <= 768 && !slider.hasClass('slick-slider')) {
      slider.slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        touchThreshold: 8,
        responsive: [
          {
            breakpoint: 680,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          }
        ]
      });
    } else if (winWidth >= 769 && slider.hasClass('slick-slider')) {
      slider.slick('unslick');
    }
  }

  window.addEventListener('resize', startSlider);
};
