'use strict';
const $ = require('jquery');
const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
module.exports = function () {
  $('.dropdown li').click((e) => {
    e.target.parentElement.parentElement.parentElement.firstElementChild.children[0].childNodes[1].innerHTML = '<span>' + e.target.defaultValue.toLowerCase() + '</span>';
    e.target.parentElement.parentElement.parentElement.classList.remove('reveal');
  });

  $('.subject').click((e) => {
    console.log('working');
    var showme = $(e.target);
    if (showme.parent().hasClass('reveal')) {
      showme.parent().removeClass('reveal');
    } else {
      $('.selection-cont').removeClass('reveal');
      e.target.parentElement.classList.toggle('reveal');
    }
  });

  $('#submit').click((e) => {
    e.preventDefault();
    $('.state .error').removeClass('show');
    let data = {
      inputs: $('form input[type="text"]'),
      name: $('form input[type="text"][name=name]').val(),
      company: $('form input[type="text"][name=company]').val(),
      location: $('form input[type="text"][name=location]').val(),
      email: $('form input[name="email"]').val(),
      interested: $('form input[name="interested"]:checked').val(),
      like: $('form input[name="like"]:checked').val(),
      radiosinterested: document.getElementsByName('interested'),
      radioslike: document.getElementsByName('like')
    };

    let emailValid = reg.test(data.email);
    reg.lastIndex = 0;
    let complete = false;

    for (let i = 0; i < data.inputs.length; i++) {
      if (data.inputs[i].type.toLowerCase() === 'text' && data.inputs[i].value !== '') {
        data.inputs[i].nextElementSibling.classList.remove('show');
      } else {
        data.inputs[i].nextElementSibling.classList.add('show');
      }
    }

    if ((data.name !== '' && data.company !== '' && data.location !== '') && emailValid && $('input[name=like]:checked').length > 0 && $('input[name=interested]:checked').length > 0) {
      complete = true;
    }

    if (emailValid) {
      $('form input[name="email"]').next().removeClass('show');
    } else {
      $('form input[name="email"]').next().addClass('show');
    }

    function vaild (radio) {
      for (let i = 0, length = radio.length; i < length; i++) {
        if (radio[i].checked) {
          radio[i].parentElement.parentElement.nextElementSibling.classList.remove('show');
          break;
        } else {
          radio[i].parentElement.parentElement.nextElementSibling.classList.add('show');
        }
      }
    }

    vaild(data.radiosinterested);
    vaild(data.radioslike);
    if (!complete) {
    } else {
      // $('form').addClass('success');
      let completeData = {
        name: `${data.name}`,
        email: `${data.email}`,
        company: `${data.company}`,
        location: `${data.location}`,
        interested: `${data.interested}`,
        like: `${data.like}`
      };
      $.ajax({
        url: '/contact',
        type: 'POST',
        data: completeData,
        complete: function (data) {
          console.log('process complete');
        },
        success: function (data) {
          $('form').addClass('success');
          console.log('success', data);
        },
        error: function (error) {
          console.log('process error', error.status, error.responseText);
        }
      });
    }
  });
};
