'use strict';
const $ = require('jquery');
const slick = require('slick-carousel');
const squareSlider = $('#square-list');
let winWidth = window.innerWidth;
module.exports = function () {
  // Remove warning for touch event. Jquery issue
  // $.event.special.touchstart = {
  //   setup: function (_, ns, handle) {
  //     if (ns.includes('noPreventDefault')) {
  //       this.addEventListener('touchstart', handle, { passive: false });
  //     } else {
  //       this.addEventListener('touchstart', handle, { passive: true });
  //     }
  //   }
  // };
  // $.event.special.touchmove = {
  //   setup: function (_, ns, handle) {
  //     if (ns.includes('noPreventDefault')) {
  //       this.addEventListener('touchmove', handle, { passive: false });
  //     } else {
  //       this.addEventListener('touchmove', handle, { passive: true });
  //     }
  //   }
  // };

  sizeCheck();
  window.addEventListener('resize', sizeCheck);
  function sizeCheck () {
    winWidth = window.innerWidth;
    if (winWidth <= 1023 && !squareSlider.hasClass('slick-slider')) {
      // squareSlider.addClass('activate');
      squareSlider.slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        touchThreshold: 8,
        verticalSwiping: false,
        responsive: [
          {
            breakpoint: 540,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          }
        ]
      });
    } else if (winWidth >= 1024 && squareSlider.hasClass('slick-slider')) {
      // squareSlider.removeClass('activate');
      squareSlider.slick('unslick');
    }
  }
};
