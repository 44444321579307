'use strict';
const $ = require('jquery');
const grid = require('./square-grid');
const preventArray = require('./square-grid-alternative');
const colorArray = require('./square-grid-color');

module.exports = function () {
  const sectionUndo = $('.undo');
  const squareSlider = $('#square-list .container');
  const squareContainer = $('#square');
  const squareBlocks = $('#square li');
  const xOut = $('.x-icon');
  let delayNum;
  let timer;
  $.fn.hasAnyClass = function () {
    for (var i = 0; i < arguments.length; i++) {
      if (this.hasClass(arguments[i])) {
        return true;
      }
    }
    return false;
  };
  function changeBack () {
    let contentBox = $('#square li .container'); // Grab content;
    if (!contentBox.length) return;
    contentBox.remove(); // remove content that was appened;
    squareBlocks.each(function (index, value) {
      let random = Math.floor(Math.random() * 200) + 100; // Random
      // let randomEnumerate = Math.floor(Math.random() * (600 - 400 + 1) + 400); // Random with a enumerate
      setTimeout(() => {
        $(this).removeClass('chosen cover blue-bg white-bg red-bg alternative prevent-click');
      }, random);
    });
  }
  // squareList.each(function (index, value) {
  //   $(this).append(`<span class="num">${index}</span>`);
  // });
  xOut.on('click', (e) => {
    e.stopPropagation();
    changeBack();
  });

  // window.addEventListener('scroll', () => {
  //   changeBack();
  // });
  squareBlocks.on('click', function (e) {
    let $this = $(this);
    // Problems came up using pointer Event on parent and child, so js is it.
    if ($this.hasAnyClass('chosen', 'cover')) return;
    let blockNum = $this.attr('grid'); // Find what grid was clicked
    let blockIndex = $this.attr('index'); // Find what grid was clicked
    let blockCoverList = grid[blockNum]; // Pull the array we need, so we know what box to cover
    let blockLocation = grid[blockNum][0]; // Find what dom index to put the content in. (Always top left block)
    let blockContent = squareSlider[blockNum]; // Grab the content thats need
    let blockChosen = $(`[index=${blockLocation}]`); // Find the choosen dom to inject content
    delayNum = 0; // This finds out what was the hightest random number was and gives it back for timing.

    squareBlocks.each(function (index, value) {
      let $this = $(this);
      let random = Math.floor(Math.random() * 400) + 200; // Random
      let randomEnumerate = Math.floor(Math.random() * (600 - 400 + 1) + 400); // Random with a enumerate
      let contentBox = $('#square li .container'); // Grab content;
      if (contentBox.length) contentBox.remove(); // remove content that was appened;
      $this.removeClass('chosen cover blue-bg white-bg red-bg alternative prevent-click');
      // Filter Active Blocks and Prevent Blocks
      let filterPreventArray = preventArray.filter(el => !blockCoverList.indexOf(el) !== -1);
      // Add prevent click on inert blocks
      if ($.inArray(index, filterPreventArray) !== -1) $this.addClass('prevent-click');
      // Clickable blocks on active
      if ($.inArray(index, blockCoverList) === -1) {
        setTimeout(() => {
          $this.addClass('alternative');
        }, random);
      }
      // Create cover blocks
      if ($.inArray(index, blockCoverList) !== -1) {
        setTimeout(() => {
          $this.addClass('cover').addClass(colorArray[blockIndex]);
        }, random);
      }
      // Find felay time when all class are added
      if (delayNum < random) delayNum = randomEnumerate;
    });
    // Append content to chosen block
    $(blockContent).clone(true).appendTo(blockChosen);
    // Let it settle and then add animation
    setTimeout(() => {
      blockChosen.addClass('chosen');
    }, delayNum);
  });
  sectionUndo.on('click', function (e) {
    if (this !== e.target) return;
    changeBack();
  });

  $('#square-list').on('destroy', function () {
    xOut.on('click', (e) => {
      e.stopPropagation();
      changeBack();
    });
  });
  // squareContainer.on('mouseleave', () => {
  //   timer = setTimeout(() => {
  //     changeBack();
  //   }, 1000);
  // });
  squareContainer.on('mouseenter', () => {
    clearTimeout(timer);
  });
};
