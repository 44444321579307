module.exports = () => {
  const win = window;
  const sections = [];
  const triggerLocation = 100;
  const activationPercentage = triggerLocation / 100;
  // Let's Grab each element with animation-section class
  const elmList = document.querySelectorAll('.animation-article');
  // Let's create an Array that stores objects for the Dom elements we need to trigger
  for (let i = 0; i < elmList.length; i++) {
    sections.push({
      element: elmList[i],
      position: elmList[i].getBoundingClientRect().top
    });
  }
  // console.log(sections);
  // Runs When Resize or scrolling & on initinal Page Load
  const displayHiddenSections = function () {
    // Find the Length of the Array
    let length = sections.length;
    // Let's check if we still need to run the code below still
    if (length > 0) {
      // Let's get the innerHeight of the window. Will * to get a precentage.
      let wh = window.innerHeight;
      // Grab where the window viewport is in the document
      let windowScroll = window.scrollY || document.documentElement.scrollTop;
      // Let's do Minus length, so we start from the last item, so 0 element is always the same.
      for (let i = length - 1; i >= 0; i--) {
        // Let's reference the array of element
        let elementPosition = sections[i].position;
        // sections[i].element.setAttribute('w-p', windowScroll + (wh * activationPercentage));
        // sections[i].element.setAttribute('t-p', sections[i].position);

        if (elementPosition <= windowScroll + (wh * activationPercentage)) {
          // If condition is met remove hide class
          sections[i].element.classList.remove('animate-in');
          // sections[i].element.setAttribute('triggered', 'true');
          // Remove that element from the list as well
          sections.splice(i, 1);
        }
      }
    } else {
      // If all is done remove these two events listeners
      win.removeEventListener('scroll', displayHiddenSections);
      win.removeEventListener('resize', displayHiddenSections);
    }
  };
  win.addEventListener('scroll', displayHiddenSections);
  win.addEventListener('resize', displayHiddenSections);

  displayHiddenSections();
};
