const ua = require('./modules/ua-detection');

var Modules = {
  init: function () {
    ua.init();
    require('./modules/nav')();
    // require('./modules/nav-hover')();
    require('./modules/achor')();
    require('./modules/circles')();
    require('./modules/insight-slider')();
    require('./modules/square/square-slider')();
    require('./modules/square/square-container')();
    require('./modules/form')();
    document.onreadystatechange = function () {
      if (document.readyState === 'complete') {
        require('./modules/animation')();
        require('./modules/rellax')();
      }
    };
  }
};

module.exports = Modules;
