'use strict';
const $ = require('jquery');
module.exports = function () {
  const body = document.getElementsByTagName('body')[0];
  const hamburger = document.getElementById('hamburger');
  const buttons = $('#nav li a');

  buttons.on('click', function () {
    body.classList.remove('active');
  });
  // Click Hamburger
  hamburger.addEventListener('click', (e) => {
    body.classList.toggle('active');
  });
  // Window Resize
  window.addEventListener('resize', () => {
    if (body.classList.contains('active') && window.innerWidth <= 1024) body.classList.remove('active');
  });
};
