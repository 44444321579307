'use strict';
const $ = require('jquery');
module.exports = function () {
  const $article = $('.articleSlider a');
  $article.hover(function (e) {
    let $target = $(this)[0];
    $article.each(function (index, value) {
      if ($target === $(this)[0]) {
        $(this).addClass('active');
      } else {
        $(this).addClass('inactive').removeClass('active');
      }
    });
  }, () => {
    $article.removeClass('active inactive');
  });
};
