'use strict';
const Rellax = require('rellax');

module.exports = function () {
  const rellax = new Rellax('.rellax', {
    speed: -2,
    center: false,
    wrapper: null,
    round: true,
    vertical: true,
    horizontal: false
  });
};
